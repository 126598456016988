import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Twitter = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
        twitter: file(relativePath: { eq: "social-icons/twitter.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.twitter.childImageSharp.fluid} alt="Twitter logo"
  />
}

export default Twitter