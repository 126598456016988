import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Instagram = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
        instagram: file(relativePath: { eq: "social-icons/instagram.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.instagram.childImageSharp.fluid} alt="Instagram logo"
  />
}

export default Instagram