import React from "react"
import { Row, Col } from "react-bootstrap"
import Styled from "styled-components"
import LinkedIn from "../components/social-icons/linkedin"
import Instagram from "../components/social-icons/instagram"
import Facebook from "../components/social-icons/facebook"
import Twitter from "../components/social-icons/twitter"

const Trademark = Styled.div`
text-align: center;
color: #ddd;
`
const Locations = Styled(Row)`
border-top: 1px solid white;
color: white;
padding: 5em;
text-align: center;
`

const Footer = () => (
    <div style={{ marginTop: `3em` }}>
        <Locations>
            <Col md="4" sm="12" style={{ margin: `auto` }}>
                <p style={{ fontSize: `1em` }}><b>HAG LATAM</b></p>
                <p style={{ fontSize: `0.7em` }}>CURITIBA, PR - BRAZIL</p>
                <p style={{ fontSize: `0.7em` }}><a href="mailto:contact@hag.ventures">CONTACT@HAG.VENTURES</a></p>
            </Col>
            <Col md="4" sm="12">
                <p style={{ fontSize: `1em` }}><b>HAG USA</b></p>
                <p style={{ fontSize: `0.7em` }}> MIDDLETOWN - USA</p>
                <p style={{ fontSize: `0.7em` }}><a href="mailto:contact@hag.ventures">CONTACT@HAG.VENTURES</a></p>
            </Col>
            <Col md="4" sm="12">
                <p style={{ fontSize: `1em` }}><b>HAG EUROPE</b></p>
                <p style={{ fontSize: `0.7em` }}>TALLINN - ESTONIA</p>
                <p style={{ fontSize: `0.7em` }}><a href="mailto:contact@hag.ventures">CONTACT@HAG.VENTURES</a></p>
            </Col>
        </Locations>
        <Row style={{ marginTop: `3em` }}>
            <Col md="4" sm="12" style={{ margin: `auto` }}>
                <Trademark>© {new Date().getFullYear()}, HAG Ventures. <br />All Rights Reserved.</Trademark>
            </Col>
            <Col md="4" sm="12" style={{ margin: `auto` }}>
                <Row>
                    <Col><a aria-label="social link" href="https://www.linkedin.com/company/haggroup" target="blank"><LinkedIn style={{ width: `2em`, margin: `auto` }} /></a></Col>
                    <Col><a aria-label="social link" href="https://www.instagram.com/hag.group/" target="blank"><Instagram style={{ width: `2em`, margin: `auto` }} /></a></Col>
                    <Col><a aria-label="social link" href="https://www.facebook.com/haggroup1" target="blank"><Facebook style={{ width: `2em`, margin: `auto` }} /></a></Col>
                    <Col><a aria-label="social link" href="https://twitter.com/haggroup1" target="blank"><Twitter style={{ width: `2em`, margin: `auto` }} /></a></Col>
                </Row>
            </Col>
        </Row>
    </div>
)

export default Footer